import SparkMD5 from 'spark-md5';

class FileProcessor {
	paused: boolean;

	file: File;

	chunkSize: number;

	unpauseHandlers: Array<() => void>;

	constructor( file: File, chunkSize: number ) {
		this.paused = false;
		this.file = file;
		this.chunkSize = chunkSize;
		this.unpauseHandlers = [];
	}

	async run( fn: ( checksum: string, index: number, chunk: ArrayBuffer ) => Promise<boolean|void>, startIndex = 0, endIndex?: number ): Promise<void> {
		const file = this.file;
		const chunkSize = this.chunkSize;

		const totalChunks = Math.ceil( file.size / chunkSize );
		const spark = new SparkMD5.ArrayBuffer();


		const processIndex = async( index: number ): Promise<boolean> => {
			if ( index === totalChunks || index === endIndex ) {
				return true;
			}

			if ( this.paused ) {
				await waitForUnpause();
			}

			const start = index * chunkSize;
			const section = file.slice( start, start + chunkSize );
			const chunk = await getData( section );
			const checksum = getChecksum( spark, chunk );

			const shouldContinue = await fn( checksum, index, chunk );
			if ( false !== shouldContinue ) {
				return processIndex( index + 1 );
			}

			return false;
		};

		const waitForUnpause: () => Promise<void> = () => {
			return new Promise( ( resolve ) => {
				this.unpauseHandlers.push( resolve );
			} );
		};

		await processIndex( startIndex );
	}

	pause(): void {
		this.paused = true;
	}

	unpause(): void {
		this.paused = false;
		this.unpauseHandlers.forEach( ( fn ) => {
			return fn();
		} );
		this.unpauseHandlers = [];
	}
}

function getChecksum( spark: SparkArrayBuffer, chunk: ArrayBuffer ) {
	spark.append( chunk );
	const state = spark.getState();
	const checksum = spark.end();
	spark.setState( state );

	return checksum;
}

async function getData( blob: Blob ): Promise<ArrayBuffer> {
	return new Promise( ( resolve, reject ) => {
		const reader = new window.FileReader();
		reader.onload = () => {
			if ( 'string' === typeof reader.result ) {
				reject( new Error( 'FileReader had a string result, expected an ArrayBuffer.' ) );

				return;
			}

			if ( !reader.result ) {
				reject( new Error( 'FileReader had a null result' ) );

				return;
			}

			return resolve( reader.result );
		};
		reader.onerror = reject;
		reader.readAsArrayBuffer( blob );
	} );
}

export default FileProcessor;
