let init = () => {
	if ( 'complete' !== document.readyState ) {
		return;
	}

	init = () => {}; // noop

	const selects = document.getElementsByClassName( 'js-select-other' );
	// check if form exists
	if ( 0 >= selects.length ) {
		return;
	}

	// listen to change event
	for ( let i = 0; i < selects.length; i++ ) {
		selects[i].addEventListener( 'change', function() {
			// Check if has for dataAttribute
			if ( !this.hasAttribute( 'data-for' ) ) {
				return;
			}

			// Select and check if other exists
			const other = document.querySelector( `.js-select-other__option[data-for="${this.getAttribute( 'data-for' )}"]` );
			if ( !other ) {
				return;
			}

			// Now lets show or hide the other option
			if ( 'other' === this.value ) {
				other.classList.remove( 'form__item--hidden' );
			} else {
				other.classList.add( 'form__item--hidden' );
			}
		} );
	}
};

document.addEventListener( 'readystatechange', () => {
	init();
}, false );

init();
