const initChangeDetection = ( form ) => {
	Array.from( form ).forEach( ( el ) => {
		el.dataset.originalValue = String( el.value );
	} );
};

const formHasChanges = ( form ) => {
	return Array.from( form ).some( ( el ) => {
		return 'originalValue' in el.dataset && el.dataset.originalValue !== String( el.value );
	} );
};

let init = () => {
	if ( 'complete' !== document.readyState ) {
		return;
	}

	init = () => {}; // noop

	const form = document.getElementsByClassName( 'js-save-before-navigation' )[0];
	// check if form exists
	if ( !form ) {
		return;
	}

	// Save the original values of each form element
	initChangeDetection( form );

	// On form submit set a submit flag
	let submit = false;
	form.addEventListener( 'submit', () => {
		submit = true;
	} );

	window.addEventListener( 'beforeunload', ( e ) => {
		if ( formHasChanges( form ) && !submit ) {
			// Cancel the event
			e.preventDefault();
			// Chrome requires returnValue to be set
			e.returnValue = '';
		}
	} );
};

document.addEventListener( 'readystatechange', () => {
	init();
}, false );

init();
