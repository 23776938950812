export function DifferentChunkError( chunkIndex: number ): Error {
	return new Error( `Chunk at index '${chunkIndex}' is different to original` );
}

export function FileAlreadyUploadedError( id: string, url: string ): Error {
	return new Error( `File '${id}' has already been uploaded to unique url '${url}'` );
}

export function UrlNotFoundError( url: string ): Error {
	return new Error( `Upload URL '${url}' has either expired or is invalid` );
}

export function UploadFailedError( status: string|number ): Error {
	return new Error( `HTTP status ${status} received from GCS, consider retrying` );
}

export function UnknownResponseError(): Error {
	return new Error( 'Unknown response received from GCS' );
}

export function MissingOptionsError(): Error {
	return new Error( 'Missing options for Upload' );
}

export function UploadIncompleteError(): Error {
	return new Error( 'Upload is not complete' );
}

export function InvalidChunkSizeError( chunkSize: number ): Error {
	return new Error( `Invalid chunk size ${chunkSize}, must be a multiple of 262144` );
}

export function UploadAlreadyFinishedError(): Error {
	return new Error( 'Upload instance has already finished' );
}


export function UploadNotResumable(): Error {
	return new Error( 'Upload is not resumable' );
}
