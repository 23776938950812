const selectors = {
	input: '.js-file-input__input',
	progress: '.js-file-input__label__progress',
};

class MrFileInput extends HTMLElement {
	connectedCallback() {
		window.requestAnimationFrame( () => {
			this.input = this.querySelector( selectors.input );
			this.progress = this.querySelector( selectors.progress );
			this.progressData = {};

			this.error = false;

			this.bind();
		} );
	}

	bind() {
		this.input.addEventListener( 'error', ( e ) => {
			this.classList.add( 'has-uploads' );
			this.errorHandler( e );
		} );

		this.input.addEventListener( 'progress', ( e ) => {
			this.classList.add( 'has-uploads' );
			this.progressHandler( e );
		} );

		this.input.addEventListener( 'done', ( e ) => {
			this.doneHandler( e );
		} );
	}

	errorHandler( e ) {
		this.newFileError( e );
	}

	doneHandler( e ) {
		if ( e.detail.file ) {
			const inputField = this.querySelector( `#uploaded-file-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}` );
			if ( !inputField ) {
				return;
			}

			inputField.classList.add( 'upload-success' );
			inputField.name = e.detail.inputName;
		}

		if ( e.detail.allDone ) {
			this.progressData = {};
		}
	}

	progressHandler( e ) {
		this.progressData[e.detail.file] = e.detail.progress;
		let uploadedBytes = 0;
		let totalBytes = 0;

		Object.keys( this.progressData ).forEach( ( key ) => {
			uploadedBytes += this.progressData[key].uploadedBytes;
			totalBytes += this.progressData[key].totalBytes;
		} );

		const progress = uploadedBytes / totalBytes;
		this.progress.style.transform = `scale(${progress},1) scale3d(${progress},1,1)`;

		this.newFileCheckbox( e );
	}

	newFileCheckbox( e ) {
		const newUpload = this.querySelector( '.new-upload' );
		if ( !newUpload ) {
			return;
		}

		const inputField = this.querySelector( `#uploaded-file-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}` );
		if ( inputField ) {
			this.updateFileCheckbox( e );

			return;
		}

		const a = document.createElement( 'input' );
		a.className = 'new-upload__input';
		a.type = 'checkbox';
		a.id = `uploaded-file-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}`;
		a.name = '';
		a.checked = 'checked';
		a.value = '';
		a.hidden = 'hidden';

		const b = document.createElement( 'a' );
		b.id = `uploaded-file__link-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}`;
		b.className = 'new-upload__link';
		b.target = '_blank';
		b.innerHTML = e.detail.file;

		const c = document.createElement( 'label' );
		c.className = 'new-upload__label';
		c.setAttribute( 'for', `uploaded-file-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}` );
		c.setAttribute( 'data-remove', 'remove' );
		c.setAttribute( 'data-undo', 'undo' );

		const d = document.createElement( 'li' );
		d.className = 'new-upload__item';
		d.id = `li-uploaded-file-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}`;

		d.appendChild( a );
		d.appendChild( b );
		d.appendChild( c );

		newUpload.appendChild( d );
		this.updateFileCheckbox( e );
	}

	updateFileCheckbox( e ) {
		if ( !e.detail.url ) {
			return;
		}

		if ( !e.detail.file ) {
			return;
		}

		const inputField = this.querySelector( `#uploaded-file-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}` );
		if ( !inputField ) {
			return;
		}

		inputField.value = e.detail.url;

		const assetLink = this.querySelector( `#uploaded-file__link-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}` );
		if ( !assetLink ) {
			return;
		}

		assetLink.href = e.detail.url;
	}

	newFileError( e ) {
		const newUpload = this.querySelector( '.new-upload' );
		if ( !newUpload ) {
			return;
		}

		let fileNameSuffix = '';
		if ( 30 < e.detail.file.length ) {
			fileNameSuffix = '...';
		}

		let errMessage = '<p class="new-upload__error-file">' + e.detail.file.substring( 0, Math.min( 30, e.detail.file.length ) ) + fileNameSuffix + '</p>';
		if ( e.detail.error ) {
			errMessage = errMessage + '<p class="new-upload__error-message">upload failed : ' + e.detail.error + '</p>';
		} else {
			errMessage += '<p class="new-upload__error-message">upload failed</p>';
		}

		const a = document.createElement( 'li' );
		a.className = 'new-upload__item';
		a.innerHTML = errMessage;
		a.id = `li-uploaded-file-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}`;

		const fileUpload = newUpload.querySelector( `#li-uploaded-file-${e.detail.file.split( '.' ).join( '-' )}-${e.detail.timestamp}` );
		if ( fileUpload ) {
			newUpload.replaceChild( a, fileUpload );
		} else {
			newUpload.appendChild( a );
		}
	}
}

customElements.define( 'mr-file-input', MrFileInput );
