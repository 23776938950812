const STORAGE_KEY = '__gcsBrowserUpload';

export default class FileMeta {
	id: string;

	fileSize: number;

	chunkSize: number;

	storage: Storage;

	constructor( id: string, fileSize: number, chunkSize: number, storage: Storage ) {
		this.id = id;
		this.fileSize = fileSize;
		this.chunkSize = chunkSize;
		this.storage = storage;
	}

	getMeta(): Meta {
		const meta = this.storage.getItem( `${STORAGE_KEY}.${this.id}` );
		if ( meta ) {
			return JSON.parse( meta );
		}

		return {
			checksums: [],
			chunkSize: this.chunkSize,
			started: false,
			fileSize: this.fileSize,
		};

	}

	setMeta( meta: Meta|null ): void {
		const key = `${STORAGE_KEY}.${this.id}`;
		if ( meta ) {
			this.storage.setItem( key, JSON.stringify( meta ) );
		} else {
			this.storage.removeItem( key );
		}
	}

	isResumable(): boolean {
		const meta = this.getMeta();

		return meta.started && this.chunkSize === meta.chunkSize;
	}

	getResumeIndex(): number {
		return this.getMeta().checksums.length;
	}

	getFileSize(): number {
		return this.getMeta().fileSize;
	}

	addChecksum( index: number, checksum: string ): void {
		const meta = this.getMeta();
		meta.checksums[index] = checksum;
		meta.started = true;
		this.setMeta( meta );
	}

	getChecksum( index: number ): string {
		return this.getMeta().checksums[index];
	}

	reset(): void {
		this.setMeta( null );
	}
}

type Meta = {
	checksums: Array<string>
	chunkSize: number
	started: boolean
	fileSize: number
}
