let init = () => {
	if ( 'complete' !== document.readyState ) {
		return;
	}

	init = () => {}; // replace with noop

	const forms = Array.from( document.querySelectorAll( '.js-submit-blocker' ) );
	forms.forEach( ( form ) => {
		const submitBlocker = document.createElement( 'div' );
		submitBlocker.classList.add( 'submit-blocker' );
		form.appendChild( submitBlocker );

		form.addEventListener( 'submit', () => {
			form.classList.add( 'submit-blocked' );
		} );

	} );
};

document.addEventListener( 'readystatechange', () => {
	init();
}, false );

init();
