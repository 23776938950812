import Upload from '../gcs-browser-upload/upload';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

const uploadHandler = ( form, input, file ) => {
	const filename = file.name.replace( /[^a-z0-9.]+/ig, '-' );
	const timestamp = ( new Date() ).getTime();

	const dispatchEventPrePostUpload = ( name = null, error = null ) => {
		input.dispatchEvent(
			new CustomEvent(
				name,
				{
					detail: {
						timestamp: timestamp,
						file: filename,
						inputName: input.getAttribute( 'data-url-name' ),
						error: error,
						progress: {
							totalBytes: file.size,
							uploadedBytes: 0,
							chunkIndex: 0,
							chunkLength: 0,
						},
					},
				}
			)
		);
	};


	if ( '' === filename ) {
		return new Promise( ( resolve ) => {
			dispatchEventPrePostUpload( 'error', 'file name can\t be empty' );

			resolve( 'empty' );
		} );
	}

	if ( 50 < filename.length ) {
		return new Promise( ( resolve ) => {
			dispatchEventPrePostUpload( 'error', 'file name must be 50 characters or less' );

			resolve( 'empty' );
		} );
	}

	dispatchEventPrePostUpload( 'progress' );

	return requestUploadURL( filename ).then( ( urlResp ) => {

		const dispatchEventUploading = ( name = null, progress = null, error = null ) => {
			input.dispatchEvent(
				new CustomEvent(
					name,
					{
						detail: {
							timestamp: timestamp,
							file: filename,
							error: error,
							url: 'https://wp.assets.sh/' + urlResp.filepath,
							inputName: input.getAttribute( 'data-url-name' ),
							progress: progress,
						},
					}
				)
			);
		};

		const upload = new Upload( {
			id: urlResp.filepath,
			url: urlResp.uploadurl,
			file: file,
			chunkSize: 262144 * 4, // optional - chunk size must be a multiple of 262144
			storage: window.localStorage, // optional - storage mechanism used to persist chunk meta data
			onChunkUpload: ( info ) => {
				// Example resp : Chunk uploaded {totalBytes: 70733, uploadedBytes: 70733, chunkIndex: 0, chunkLength: 70733}
				dispatchEventUploading( 'progress', info );
			},
		} );

		return upload.start().then( () => {
			dispatchEventUploading( 'done' );

			return 'success';
		} );
	} ).catch( ( err ) => {
		console.dir( err );
		bugsnagClient.notify( err );

		// TODO: handle some errors specifically : https://github.com/QubitProducts/gcs-browser-upload/blob/master/src/errors.js
		dispatchEventPrePostUpload( 'error' );
		// Do not rethrow
		form.classList.add( 'has-upload-errors' );
	} );
};

const requestUploadURL = ( filename ) => {
	const urlParams = new URLSearchParams( window.location.search );
	const k = urlParams.get( 'k' );
	const application = urlParams.get( 'application' );

	let url = '/wp-json/direct_uploads/file.json?action=setup_direct_upload&filename=' + encodeURIComponent( filename );
	url = url + '&k=' + k;
	url = url + '&application=' + application;

	const headers = new Headers();
	const request = new Request( url, {
		method: 'POST',
		headers: headers,
		credentials: 'same-origin',
	} );

	return fetch( request ).then( ( response ) => {

		// Handle HTTP errors
		if ( !response.ok ) {
			// just throw so we can handle in the catch block later
			throw new Error( `direct_uploads setup : ${response.status} ${response.statusText}` );
		}

		// this might error but we handle in the catch block later
		return response.json();
	} ).then( ( data ) => {
		if ( false === data.success ) {
			throw new Error( `direct_uploads setup : ${JSON.stringify( data )}` );
		}

		return data;
	} );
};

let init = () => {
	if ( 'complete' !== document.readyState ) {
		return;
	}

	init = () => {}; // replace with noop

	const forms = Array.from( document.querySelectorAll( '.js-gcs-browser-upload-form' ) );
	forms.forEach( ( form ) => {

		const submit = form.querySelector( 'button[type="submit"]' );
		if ( !submit ) {
			return;
		}

		const fileInputs = Array.from( form.querySelectorAll( 'input[type="file"]' ) );
		fileInputs.forEach( ( fileInput ) => {

			const changeHandler = () => {
				if ( !fileInput.files || !fileInput.files.length ) {
					return;
				}

				const uploads = [];

				Array.from( fileInput.files ).forEach( ( file ) => {
					uploads.push( uploadHandler( form, fileInput, file ) );
				} );

				fileInput.type = '';
				fileInput.type = 'file';

				if ( !uploads.length ) {
					return;
				}

				fileInput.disabled = true;

				fileInput.parentNode.classList.add( 'is-uploading' );

				submit.disabled = true; // disable submit

				Promise.all( uploads ).then( ( messages ) => {
					console.log( messages );

					fileInput.dispatchEvent( new CustomEvent( 'done', {
						detail: {
							allDone: true,
						},
					} ) );

					setTimeout( () => {
						fileInput.disabled = false;
						fileInput.parentNode.classList.remove( 'is-uploading' );
					}, 200 );

					submit.disabled = false; // enable submit

				} ).catch( ( err ) => {
					console.dir( err );

					fileInput.dispatchEvent( new CustomEvent( 'done', {
						detail: {
							allDone: true,
						},
					} ) );

					bugsnagClient.notify( err );

					setTimeout( () => {
						fileInput.disabled = false;
						fileInput.parentNode.classList.remove( 'is-uploading' );
					}, 200 );

					submit.disabled = false; // enable submit
				} );
			};

			fileInput.addEventListener( 'change', changeHandler );

		} );
	} );
};

document.addEventListener( 'readystatechange', () => {
	init();
}, false );

init();
